<template>
  <div class="contract_list_page">
    <div class="operateInfo">
      <div class="title">合同操作说明:</div>
      <div class="desc">1. 请您先下载合同，然后打印成纸质合同。</div>
      <div class="desc">2. 盖上您的公章（含骑缝章）。</div>
      <div class="desc">
        3.
        把每页扫描成清晰的图片放到同一个文件夹里，然后压缩成压缩包上传到本网站即可。
      </div>
      <div class="desc">
        4.注意:
        请您务必上传甲乙双方用印（含骑缝章）完毕后的合同，否则系统平台不予开课。上传合同时需确保与您下载时的合同主体一致,
        如擅自更改合同(包括但不限于合同主体,附件等)则合同无效,公司将保留追究法律的权利。
      </div>
    </div>
    <myTable
      title="合同下载列表"
      style="margin-bottom: 0.5rem"
      :list="downList"
      :columns="columns"
    >
      <template slot="btns" slot-scope="item">
        <div class="table_btns" v-show="item.data.row.downloadUrl">
          <div class="table_btn" @click="handleView(item.data.row.downloadUrl)">
            下载合同
          </div>
        </div>
      </template>
    </myTable>
    <comPagination
      :params="downParams"
      @changePage="changeDownPage"
    ></comPagination>
    <myTable title="合同上传列表" :list="updateList" :columns="columns">
      <template slot="top_block">
        <div class="code_block">
          <el-form :inline="true">
            <el-form-item label="待上传合同编号">
              <el-select v-model="selectValue" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-upload
            class="upload-demo"
            name="file"
            :headers="{ token: $store.state.userInfo.token }"
            :action="constant.UPLOAD_URL + '/file/upload'"
            :on-success="uploadSuccess"
            :show-file-list="false"
            :disabled="!selectValue"
          >
            <el-button class="table_top_btn_default tab">上传合同</el-button>
          </el-upload>
        </div>
      </template>
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div
            class="table_btn"
            v-if="item.data.row.uploadUrl === null ? true : false"
          >
            未上传
          </div>
          <div class="table_btn" @click="handleView(item.data.row)" v-else>
            查看
          </div>
        </div>
      </template>
    </myTable>
    <comPagination
      :params="updateParams"
      @changePage="changeUpadtePage"
    ></comPagination>
  </div>
</template>

<script>
import myTable from "@/components/admin/table.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "graduationStudentList",
  components: {
    comPagination,
    myTable,
  },
  data() {
    return {
      selectValue: "",
      options: [],
      columns: [
        {
          prop: "updatetime",
          label: "日期",
        },
        {
          prop: "contract",
          label: "合同编号",
        },
      ],
      updateList: [],
      downList: [],
      //分页
      updateParams: {
        page: 0,
        size: 10,
        total: 0,
      },
      downParams: {
        page: 0,
        size: 10,
        total: 0,
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.getUpdateInfo();
      this.getSelectInfo();
      this.getDownInfo();
    },
    // 上传列表
    getUpdateInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        name: this.keyWord,
        type: 1,
      };
      this.updateList = [];
      this.api.admin.findUpContractList(this.updateParams, data).then((res) => {
        //   res.data.id = this.$route.query.id
        //   this.list.push(res.data)
        this.updateList = res.data.list;
        this.updateList.forEach((item) => {
          item.updatetime = this.common.dateFormat(item.updatetime * 1000);
          item.contract =
            item.contractOrder.contractPrefix +
            item.contractOrder.contractNumber;
        });
        this.params.total = res.data.total;
        console.log(res);
      });
    },
    //   下载列表
    getDownInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.downList = [];
      this.api.admin.findDownContractList(this.downParams, data).then((res) => {
        //   res.data.id = this.$route.query.id
        //   this.list.push(res.data)
        this.downList = res.data.list;

        this.downList.forEach((item) => {
          item.updatetime = this.common.dateFormat(item.updatetime * 1000);
          item.contract =
            item.contractOrder.contractPrefix +
            item.contractOrder.contractNumber;
        });
        this.params.total = res.data.total;
      });
    },
    //  可以进行上传的列表
    getSelectInfo() {
      let data = {
        schoolId: parseInt(this.$store.state.userInfo.schoolId),
      };
      this.options = [];
      this.api.admin.findWaitContract(data).then((res) => {
        //   res.data.id = this.$route.query.id
        //   this.list.push(res.data)
        this.options = res.data.map((item) => {
          return {
            label: item.contractPrefix + item.contractNumber,
            value: item.id,
          };
        });
        console.log(11111111, this.options);
        console.log(res);
      });
    },
    //上传成功
    uploadSuccess(res) {
      if (res.flag) {
        this.$notice({
          type: "success",
          message: "上传成功",
        }).isShow();
        let data = {
          contractId: this.selectValue,
          uploadUrl: res.data.filename,
        };
        this.api.admin.updateContract(data).then((result) => {
          console.log(result);
        });
        this.getUpdateInfo();
      } else {
        this.$notice({
          type: "success",
          message: res.message,
        }).isShow();
      }
    },
    handleView(val) {
      console.log(val);
      var url2 = "";
      if (val.uploadUrl) {
        url2 = this.constant.URL_admin + "/" + val.uploadUrl;
      } else {
        url2 = this.constant.URL_admin + "/" + val;
      }
      window.location.href = url2;
    },
    //分页 todo 待测试
    changeDownPage(val) {
      this.downParams.page = val;
      this.getInfo();
    },
    changeUpadtePage(val) {
      this.updateParams.page = val;
      this.getInfo();
    },
    // uploadContract(){
    //   console.log('ddddd')
    //   if(!this.selectValue){
    //     this.$notice({
    //       message:
    //         "请先选择待上传合同编号",
    //     }).isShow();
    //     return false;
    //   }
    // }
  },
};
</script>

<style lang="scss">
.contract_list_page {
  .operateInfo {
    @include flex(column, flex-start, flex-start);
    margin-bottom: 10px;

    .title {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .desc {
      font-size: 24px;
    }
  }

  .code_block {
    @include flex(row, flex-end, center);

    .el-form-item {
      margin-bottom: 0;
    }

    .el-form-item__label {
      font-size: 24px;
      line-height: 60px;
    }

    .el-input__inner {
      height: 60px;
      width: 250px;
      font-size: 20px;
    }
  }
}
.notAllow{
  cursor: not-allowed;
}
</style>